<template>
    <div class="page-container">
        <md-app md-waterfall md-mode="fixed">
            <md-app-toolbar class="md-primary">
                <div class="md-toolbar-row">
                    <div class="md-toolbar-section-start">
                        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" class="logo" />
                        <h3 class="md-title">stock.ftvaccess.fr</h3>
                    </div>
                    <div class="md-toolbar-section-end">
                        <md-menu md-direction="bottom-end" :mdCloseOnClick="true" :mdCloseOnSelect="true">
                            <md-button class="md-icon-button" md-menu-trigger>
                                <md-icon>more_vert</md-icon>
                            </md-button>
                            <md-menu-content>
                                <md-menu-item v.if="$store.getters.logged" @click="$router.push('/stock')">
                                    <span>Recherche de programmes</span>
                                    <md-icon>search</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/password')">
                                    <span>Changer mot de passe</span>
                                    <md-icon>lock</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/confidentialite')">
                                    <span>Politique de confidentialité</span>
                                    <md-icon>policy</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/cgu')">
                                    <span>CGU</span>
                                    <md-icon>description</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/legal')">
                                    <span>Mentions légales</span>
                                    <md-icon>gavel</md-icon>
                                </md-menu-item>
                                <md-menu-item  @click="logout">
                                    <span>Déconnexion</span>
                                    <md-icon>logout</md-icon>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                </div>
            </md-app-toolbar>
            <md-app-content class="md-scrollbar md-alignment-top-center md-layout">
                <md-content>
                    <h1 class="md-title">Mentions légales</h1>
                    <h3 class="text-justify">Editeur :</h3>
                    <p class="md-body-1 text-justify">
                        <strong>
                            france.tv studio
                        </strong><br />
                        Société par Actions Simplifiée Unipersonnelle au capital de 877.300 euros<br />
                        Siège Social : 26 rue d’Oradour-sur-Glane 75015 PARIS<br />
                        RCS 335.175.188 PARIS<br />
                        <br />
                        Numéro TVA intracommunautaire : FR 05 335 175188<br />
                        <br />
                        Direction de la Publication : Arnaud Lesaunier – Président de france.tv studio<br />
                        <br />
                        Tel : 01 56 22 25 00<br />
                        <br />
                        Pour toute demande, veuillez compléter le formulaire en ligne accessible <a href="https://francetvaccess.fr/contacts/" target="_blank">ICI</a>.
                    </p>
                    <h3 class="text-justify">Concepteur  :</h3>
                    <p class="md-body-1 text-justify">
                        <strong>PROJEKTILES</strong><br />
                        <br />
                        Siège social : 30 boulevard Eugène DECROS, 93260 LES LILAS<br />
                        <br />
                        RCS : Bobigny 502395460
                    </p>
                    <h3 class="text-justify">Maintenance    :</h3>
                    <p class="md-body-1 text-justify">
                        <strong>PROJEKTILES</strong><br />
                        <br />
                        Siège social : 30 boulevard Eugène DECROS, 93260 LES LILAS<br />
                        <br />
                        RCS : Bobigny 502395460
                    </p>
                    <h3 class="text-justify">Hébergeur     :</h3>
                    <p class="md-body-1 text-justify">
                        <strong>PROJEKTILES</strong><br />
                        <br />
                        Siège social : 30 boulevard Eugène DECROS, 93260 LES LILAS<br />
                        <br />
                        RCS : Bobigny 502395460<br />
                        <br />
                        localisation hébergement :<br />
                        <br />
                        <strong>OVH</strong>,<br />
                        <br />
                        SAS au capital de 10 174 560 €<br />
                        <br />
                        Siège social : 2 rue Kellermann, 59100 Roubaix – France<br />
                        <br />
                        RCS : Lille Métropole 424 761 419 00045

                    </p>
                    
                </md-content>
            </md-app-content>
        </md-app>
    </div>
</template>
      

<script>

export default {
  name: 'Legal',
  data(){
      return {
          loader: null,
      }
  },
  methods:{
      logout(){
            this.$store.dispatch('user/logout')
            .then(() => {
                this.$router.push({path: '/'});
            })
            .catch(() => {
                this.$alert("Il n'a pas été possible de vous deconnecter",'Error','error');
            })
        }
  }
  
}
</script>
<style lang="scss" scoped>
.md-app {
    min-height: 100vh;
    
    border: 1px solid rgba(#000, .12);
  }
  .text-justify{
    text-align: justify;
  }
img.logo{
    max-height: 50px;
}
.md-layout-item{
    flex-basis: 400px;
    min-width: 300px;
    max-width: 400px;
}
</style>